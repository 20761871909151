/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import validator from 'validator';
import NumberFormat from 'react-number-format';
import { states } from '../../components/ShippingInfo/states';
import quantity1 from './img/quantity1.webp';
import quantity2 from './img/quantity2.webp';
import quantity3 from './img/quantity3.webp';
import quantity4 from './img/quantity4.webp';
import bannerDesktop from './img/bannerDesktop.webp';
import bannerMobile from './img/bannerMobile.webp';
import secureIcons from './img/secureIcons.webp';
import ccIcons from './img/ccIcons.webp';
import warranty from './img/warranty.webp';
import visaIcon from './img/paymentIcons/visa.webp';
import masterIcon from './img/paymentIcons/mastercard.webp';
import amerIcon from './img/paymentIcons/amex.webp';
import discIcon from './img/paymentIcons/discover.webp';
import cvvIcon from './img/cvv.webp';
import checkIcon from './img/check.webp';
import step2 from './img/2.webp';
import step3 from './img/3.webp';
import step4 from './img/4.webp';
import wifiQuantity1 from './img/wifi/quantity1.webp';
import wifiQuantity2 from './img/wifi/quantity2.webp';
import wifiQuantity3 from './img/wifi/quantity3.webp';
import wifiQuantity4 from './img/wifi/quantity4.webp';
import wifiBannerDesktop from './img/wifi/bannerDesktop.webp';
import wifiBannerMobile from './img/wifi/bannerMobile.webp';

import { buildQueryParams, getDollarString, getPricePerEach, getSavingsPercent } from '../../utils/helpers';
import styles from './TestCheckout.module.sass';
import axios from 'axios';
import Cookies from 'js-cookie';
import queryString from 'query-string';

const images = {
  1: quantity1,
  2: quantity2,
  3: quantity3,
  4: quantity4,
};

const wifiImages = {
  1: wifiQuantity1,
  2: wifiQuantity2,
  3: wifiQuantity3,
  4: wifiQuantity4,
};

const isWifiOffer = location.href.includes('65aedaba6444950011eb4794');

const Headline = () => {
  return (
    <div className={styles.headline}>
      <p>Your Up To {isWifiOffer ? 50 : 71}% OFF Discount Has Been Applied!</p>
    </div>
  );
};

const Banner = () => {
  return (
    <div className={styles.banner}>
      <img
        className={styles.desktopOnly}
        src={isWifiOffer ? wifiBannerDesktop : bannerDesktop}
        alt='desktop banner'
      />
      <img
        className={styles.mobileOnly}
        src={isWifiOffer ? wifiBannerMobile : bannerMobile}
        alt='mobile banner'
      />
    </div>
  );
};

const SecureCheckout = () => {
  return (
    <div className={styles.secureCheckout}>
      <p>
        <i className='fa-solid fa-shield-check'></i>
        Secure Checkout
      </p>
      <img src={secureIcons} alt='secure icons' />
    </div>
  );
};

const Product = ({ product, mostPopularProductIds, selectedProduct, onProductSelect }) => {
  const isMostPopular = mostPopularProductIds.includes(product.id);
  const isSelected = selectedProduct.id === product.id;

  return (
    <div
      className={`${styles.product} ${isMostPopular && styles.mostPopularProduct} ${
        isSelected && styles.selectedProduct
      }`}
      onClick={() => onProductSelect(product)}
    >
      {isMostPopular && (
        <div className={styles.mostPopularBadge}>
          <p>
            <i className='fa-solid fa-star'></i>RECOMMENDED DEAL
          </p>
        </div>
      )}
      <div className={styles.productMobileInner}>
        <div className={styles.productName} dangerouslySetInnerHTML={{ __html: product.title }}></div>
        <div className={styles.mobileOnly}>
          <div className={styles.productOldPrice}>
            <s>{getDollarString(product.oldPrice)}</s>
          </div>
          <div className={styles.productPrice}>
            <b>{getDollarString(product.price)}</b>
          </div>
          <div className={styles.productSavings}>
            <p>
              <i className='fa-solid fa-circle-check'></i>
              {getSavingsPercent(product.oldPrice, product.price).toFixed()}% Savings!
            </p>
          </div>
          <div className={styles.productEachPrice}>
            <p>
              <i className='fa-solid fa-circle-check'></i>
              {getPricePerEach(product)}/each
            </p>
          </div>
        </div>
      </div>
      <div className={styles.productImage}>
        <img src={isWifiOffer ? wifiImages[product.quantity] : images[product.quantity]} alt='product' />
      </div>
      <div className={styles.desktopOnly}>
        <div className={styles.productOldPrice}>
          <s>{getDollarString(product.oldPrice)}</s>
        </div>
        <div className={styles.productPrice}>
          <b>{getDollarString(product.price)}</b>
        </div>
        <div className={styles.productSavings}>
          <p>
            <i className='fa-solid fa-circle-check'></i>
            {getSavingsPercent(product.oldPrice, product.price).toFixed()}% Savings!
          </p>
        </div>
        <div className={styles.productEachPrice}>
          <p>
            <i className='fa-solid fa-circle-check'></i>
            {getPricePerEach(product)}/each
          </p>
        </div>
      </div>
    </div>
  );
};

const ProductList = ({ offer, selectedProduct, onProductSelect }) => {
  const { productsData, mostPopularProductIds } = offer;

  return (
    <div className={styles.productList}>
      {productsData.map((product, index) => {
        return (
          <Product
            key={index}
            product={product}
            mostPopularProductIds={mostPopularProductIds}
            selectedProduct={selectedProduct}
            onProductSelect={onProductSelect}
          />
        );
      })}
    </div>
  );
};

const ProductSelector = ({ offer, selectedProduct, onProductSelect, onOrderNowClick }) => {
  return (
    <div className={styles.productSelector}>
      <SecureCheckout />
      <ProductList offer={offer} selectedProduct={selectedProduct} onProductSelect={onProductSelect} />
      <GreenButton text='ORDER NOW' onClick={() => onOrderNowClick()} />
    </div>
  );
};

const GreenButton = ({ text, onClick }) => {
  return (
    <button onClick={onClick} className={styles.greenButton} type='button'>
      {text}
    </button>
  );
};

const PaymentSelector = ({ shippingFormRef }) => {
  return (
    <div className={styles.paymentSelector}>
      <div className={styles.paymentSelectorTitle}>Payment</div>
      <div className={styles.paymentSelectorSubTitle}>
        <p>PAY WITH CREDIT CARD</p>
      </div>

      <div className={styles.paymentSelectorCreditCard}>
        <GreenButton
          text='Checkout'
          onClick={() => shippingFormRef.current.scrollIntoView({ behavior: 'smooth' })}
        />
      </div>
      <div className={`${styles.paymentSelectorSubTitle} ${styles.paymentSelectorSubTitleSafe}`}>
        <p>
          GUARANTEED <span>SAFE</span> CHECKOUT
        </p>
      </div>
      <div className={styles.paymentSelectorIcons}>
        <img src={ccIcons} alt='secure checkout icons' />
      </div>
    </div>
  );
};

const ShippingForm = React.forwardRef(({ onComplete, selectedProduct, brand }, ref) => {
  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCity: '',
    shippingCountry: 'US',
    shippingState: 'AL',
    shippingZip: '',
    billingFirstName: '',
    billingLastName: '',
    billingAddress1: '',
    billingCity: '',
    billingState: '',
    billingCountry: 'US',
    billingZip: '',
    billingSameAsShipping: true,
  });

  const [errors, setErrors] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingCity: '',
    shippingCountry: '',
    shippingState: '',
    shippingZip: '',
  });

  function handleCompleteClick() {
    // Initialize an errors object
    let newErrors = {};

    // Validate each field
    if (!validator.isEmail(state.email)) {
      newErrors.email = 'Invalid email';
    }
    if (validator.isEmpty(state.firstName)) {
      newErrors.firstName = 'First name is required';
    }
    if (validator.isEmpty(state.lastName)) {
      newErrors.lastName = 'Last name is required';
    }
    if (!validator.isMobilePhone(state.phone, 'en-US', { strictMode: false })) {
      newErrors.phone = 'Invalid phone number';
    }
    if (validator.isEmpty(state.shippingAddress1)) {
      newErrors.shippingAddress1 = 'Shipping address is required';
    }
    if (validator.isEmpty(state.shippingCity)) {
      newErrors.shippingCity = 'City is required';
    }
    if (validator.isEmpty(state.shippingCountry)) {
      newErrors.shippingCountry = 'Country is required';
    }
    if (validator.isEmpty(state.shippingState)) {
      newErrors.shippingState = 'State is required';
    }
    if (!validator.isPostalCode(state.shippingZip, 'any')) {
      newErrors.shippingZip = 'Invalid zip/postal code';
    }

    // If there are any errors, update the errors state with the newErrors object
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // If there are no errors, clear the errors state and log the form state
      setErrors({
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingCity: '',
        shippingCountry: '',
        shippingState: '',
        shippingZip: '',
      });
      onComplete(state);
    }
  }

  return (
    <div className={styles.form} ref={ref}>
      <div className={styles.formTitle}>SHIPPING</div>
      <div className={styles.formSubTitle}>Enter your contact information:</div>
      {[
        { label: 'Email', id: 'email-input', type: 'text', stateKey: 'email' },
        { label: 'First Name', id: 'first-name-input', type: 'text', stateKey: 'firstName' },
        { label: 'Last Name', id: 'last-name-input', type: 'text', stateKey: 'lastName' },
        { label: 'Phone', id: 'phone-input', type: 'text', stateKey: 'phone' },
        { label: 'Street Address', id: 'address1-input', type: 'text', stateKey: 'shippingAddress1' },
        {
          label: 'Apt / Suite / Other (optional)',
          id: 'address2-input',
          type: 'text',
          stateKey: 'shippingAddress2',
        },
        { label: 'City', id: 'city-input', type: 'text', stateKey: 'shippingCity' },
        // Add more inputs as needed
      ].map(({ label, id, type, stateKey }) => {
        if (stateKey === 'phone') {
          return (
            <div className={styles.formInput} key='phone-input'>
              <label
                className={`${styles.inputLabel} ${state.phone && styles.inputLabelActive}`}
                htmlFor='phone-input'
              >
                Phone
              </label>
              <NumberFormat
                id='phone-input'
                format='(###) ###-####'
                mask='_'
                value={state.phone}
                onValueChange={({ formattedValue }) => {
                  setState({ ...state, phone: formattedValue });
                }}
                className={errors.phone && styles.error}
              />
              {errors.phone && <div className={styles.error}>{errors.phone}</div>}
            </div>
          );
        } else {
          return (
            <div className={styles.formInput} key={id}>
              <label
                className={`${styles.inputLabel} ${state[stateKey] && styles.inputLabelActive}`}
                htmlFor={id}
              >
                {label}
              </label>
              <input
                id={id}
                type={type}
                value={state[stateKey]}
                onChange={(e) => setState({ ...state, [stateKey]: e.target.value })}
              />
              {errors[stateKey] && <div className={styles.error}>{errors[stateKey]}</div>}
            </div>
          );
        }
      })}

      <div className={styles.formInput}>
        <label
          className={`${styles.inputLabel} ${state.shippingCountry && styles.inputLabelActive}`}
          htmlFor='country-select'
        >
          Country
        </label>
        <select
          id='country-select'
          value={state.shippingCountry}
          onChange={(e) => setState({ ...state, shippingCountry: e.target.value })}
        >
          <option value='US'>United States</option>
          <option value='CA'>Canada</option>
        </select>
        {errors.shippingCountry && <div className={styles.error}>{errors.shippingCountry}</div>}
      </div>
      <div className={styles.formRow}>
        <div className={styles.formInput}>
          <label
            className={`${styles.inputLabel} ${state.shippingState && styles.inputLabelActive}`}
            htmlFor='state-select'
          >
            State
          </label>
          <select
            id='state-select'
            value={state.shippingState}
            onChange={(e) => setState({ ...state, shippingState: e.target.value })}
          >
            {Object.entries(states[state.shippingCountry]).map(([code, name], index) => {
              return (
                <option key={index} value={code}>
                  {name}
                </option>
              );
            })}
          </select>
          {errors.shippingState && <div className={styles.error}>{errors.shippingState}</div>}
        </div>
        <div className={styles.formInput}>
          <label
            className={`${styles.inputLabel} ${state.shippingZip && styles.inputLabelActive}`}
            htmlFor='zip-input'
          >
            Zip / Postal Code
          </label>
          <input
            id='zip-input'
            type='text'
            value={state.shippingZip}
            onChange={(e) => setState({ ...state, shippingZip: e.target.value })}
          />
          {errors.shippingZip && <div className={styles.error}>{errors.shippingZip}</div>}
        </div>
      </div>

      <div className={styles.mobileOnly}>
        <OrderSummary selectedProduct={selectedProduct} brand={brand} />
      </div>
      <GreenButton onClick={() => handleCompleteClick()} text='COMPLETE SECURE PURCHASE' />
    </div>
  );
});

const OrderSummary = ({ selectedProduct, brand }) => {
  return (
    <div className={styles.orderSummaryWrapper}>
      <div className={styles.orderSummary}>
        <div className={styles.orderSummaryInner}>
          <div className={styles.orderSummaryTitle}>Order Summary</div>
          <div className={styles.orderSummaryItems}>
            <div className={styles.orderSummaryItem}>
              <div className={styles.orderSummaryItemLeft}>
                <div
                  className={styles.orderSummaryItemName}
                  dangerouslySetInnerHTML={{ __html: selectedProduct.title }}
                ></div>
                <div className={styles.orderSummaryItemEdit} onClick={() => window.scrollTo(0, 0)}>
                  Edit
                </div>
              </div>
              <div className={styles.orderSummaryItemRight}>
                <div className={styles.orderSummaryItemQty}>QTY: {selectedProduct.quantity}</div>
                <div className={styles.orderSummaryItemPrice}>{getDollarString(selectedProduct.price)}</div>
              </div>
            </div>
          </div>

          <div className={styles.orderSummaryNumbers}>
            <div className={styles.orderSummaryNumbersLine}>
              <div className={styles.orderSummaryNumbersLeft}>Subtotal:</div>
              <div className={styles.orderSummaryNumbersRight}>{getDollarString(selectedProduct.price)}</div>
            </div>

            <div className={styles.orderSummaryNumbersLine}>
              <div className={styles.orderSummaryNumbersLeft}>Discount:</div>
              <div className={styles.orderSummaryNumbersRight}>
                <span>{getDollarString(selectedProduct.price - selectedProduct.oldPrice)}</span>
              </div>
            </div>
            <div className={styles.orderSummaryNumbersLine}>
              <div className={styles.orderSummaryNumbersLeft}>Shipping:</div>
              <div className={styles.orderSummaryNumbersRight}>
                {getDollarString(selectedProduct.shippingPrice)}
              </div>
            </div>

            <div className={styles.orderSummaryNumbersLine}>
              <div className={styles.orderSummaryNumbersLeft}>Total:</div>
              <div className={styles.orderSummaryNumbersRight}>
                {getDollarString(selectedProduct.price * 1 + selectedProduct.shippingPrice * 1)}
              </div>
            </div>
          </div>

          <div className={styles.orderSummaryMoneyBack}>
            <img src={warranty} alt='warranty 30 days money back' />
            <div className={styles.orderSummaryMoneyBackText}>
              <p>All pricing is in United States Dollars.</p>
              <small>
                <b>30 DAY GUARANTEE:</b> {brand} offers a 30 day guarantee on all unused purchases. Simply
                send the item(s) back to us in the original packaging for a full refund or replacement, less
                S&H.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Shipping = React.forwardRef(({ selectedProduct, onComplete, brand }, ref) => {
  const shippingFormRef = useRef(null);

  return (
    <div ref={ref} className={styles.shipping}>
      <div className={styles.shippingInner}>
        <ShippingForm
          brand={brand}
          ref={shippingFormRef}
          selectedProduct={selectedProduct}
          onComplete={onComplete}
        />
      </div>
      <div className={styles.desktopOnly}>
        <OrderSummary selectedProduct={selectedProduct} brand={brand} />
      </div>
    </div>
  );
});

const PaymentForm = ({ onComplete, onTermsCheckboxClick, isTermsAgreed, isTermsError }) => {
  const [state, setState] = useState({
    creditCardNumber: '',
    cardHolderName: '',
    expiryMonth: '',
    expiryYear: '',
    CVV: '',
  });

  const [errors, setErrors] = useState({});

  const handlePaymentClick = () => {
    // Validate fields and set errors state
    const newErrors = {
      creditCardNumber: validator.isCreditCard(state.creditCardNumber) ? '' : 'Invalid card number',
      cardHolderName: state.cardHolderName ? '' : 'Card holder name is required',
      expiryMonth: validator.isInt(state.expiryMonth, { min: 1, max: 12 })
        ? ''
        : 'Expiry month should be between 1 and 12',
      expiryYear: validator.isInt(state.expiryYear, { min: 24 }) ? '' : 'Expiry year should be 24 or later',
      CVV: validator.isLength(state.CVV, { min: 3, max: 4 }) ? '' : 'Security code should be 3 or 4 digits',
    };

    setErrors(newErrors);

    // Check if there are no errors
    const noErrors = Object.values(newErrors).every((error) => !error);

    if (noErrors) {
      // If there are no errors, call onComplete with the current state
      onComplete(state);
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.formTitle}>PAYMENT DETAILS</div>
      <div className={styles.formSubTitle}>*Indicates a required field</div>
      <div className={styles.formInput}>
        <label
          className={`${styles.inputLabel} ${state.creditCardNumber && styles.inputLabelActive}`}
          htmlFor='card-number-input'
        >
          Card Number*
        </label>
        <NumberFormat
          id='card-number-input'
          format='#### #### #### ####'
          value={state.creditCardNumber}
          onValueChange={(values) => {
            const { value } = values;
            setState({ ...state, creditCardNumber: value });
          }}
        />
        {errors.creditCardNumber && <div className={styles.error}>{errors.creditCardNumber}</div>}
      </div>

      <div className={styles.formInput}>
        <label
          className={`${styles.inputLabel} ${state.cardHolderName && styles.inputLabelActive}`}
          htmlFor='card-holder-name-input'
        >
          Card Holder Name*
        </label>
        <input
          id='card-holder-name-input'
          type='text'
          value={state.cardHolderName}
          onChange={(e) => setState({ ...state, cardHolderName: e.target.value })}
        />
        {errors.cardHolderName && <div className={styles.error}>{errors.cardHolderName}</div>}
      </div>
      <div className={styles.formRow}>
        <div className={styles.formInput}>
          <label
            className={`${styles.inputLabel} ${state.expiryMonth && styles.inputLabelActive}`}
            htmlFor='expiry-month-input'
          >
            Expiry Month*
          </label>
          <NumberFormat
            id='expiry-month-input'
            format='##'
            value={state.expiryMonth}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, expiryMonth: value });
            }}
          />
          {errors.expiryMonth && <div className={styles.error}>{errors.expiryMonth}</div>}
        </div>
        <div className={styles.formInput}>
          <label
            className={`${styles.inputLabel} ${state.expiryYear && styles.inputLabelActive}`}
            htmlFor='expiry-year-input'
          >
            Expiry Year*
          </label>
          <NumberFormat
            id='expiry-year-input'
            format='##'
            value={state.expiryYear}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, expiryYear: value });
            }}
          />
          {errors.expiryYear && <div className={styles.error}>{errors.expiryYear}</div>}
        </div>
      </div>
      <div className={styles.formRow}>
        <div className={styles.formInput}>
          <label
            className={`${styles.inputLabel} ${state.CVV && styles.inputLabelActive}`}
            htmlFor='security-code-input'
          >
            Security Code*
          </label>
          <NumberFormat
            id='security-code-input'
            format='####'
            value={state.CVV}
            onValueChange={(values) => {
              const { value } = values;
              setState({ ...state, CVV: value });
            }}
          />
          {errors.CVV && <div className={styles.error}>{errors.CVV}</div>}
        </div>
        <div className={styles.paymentCvvNote}>
          <img src={cvvIcon} alt='cvv code' />
          <p>3 digits on the back of the card or 4 digits on the front of card</p>
        </div>
      </div>

      <div className={styles.orderSummaryTermsCheckbox}>
        <input type='checkbox' value={isTermsAgreed} id='terms-checkbox' onClick={onTermsCheckboxClick} />
        <label htmlFor='terms-checkbox'>
          I agree with the Term of service & Refund policy & Privacy Policy
        </label>
        {isTermsError && <div className={styles.error}>{isTermsError}</div>}
      </div>
      <GreenButton onClick={handlePaymentClick} text='MAKE PAYMENT' />
    </div>
  );
};

const Payment = React.forwardRef(({ onComplete, onTermsCheckboxClick, isTermsAgreed, isTermsError }, ref) => {
  return (
    <div className={styles.payment} ref={ref}>
      <div className={styles.paymentInner}>
        <div className={styles.paymentCardsIcons}>
          <img src={visaIcon} alt='visa' />
          <img src={masterIcon} alt='master' />
          <img src={amerIcon} alt='amer' />
          <img src={discIcon} alt='discover' />
        </div>
        <PaymentForm
          onComplete={onComplete}
          isTermsError={isTermsError}
          isTermsAgreed={isTermsAgreed}
          onTermsCheckboxClick={onTermsCheckboxClick}
        />
      </div>
    </div>
  );
});

const ProgressDesktop = () => {
  return (
    <div className={styles.progress}>
      <div className={styles.progressInner}>
        <div className={styles.progressStep}>
          <div className={styles.progressStepInner}>
            <div className={styles.progressStepCheck}>
              <img src={checkIcon} alt='check icon' />
            </div>
            <div className={styles.progressStepText}>
              Select <br></br> Quantity
            </div>
          </div>
          <div className={styles.progressStepLine}></div>
        </div>
        <div className={styles.progressStep}>
          <div className={styles.progressStepInner}>
            <div className={styles.progressStepCheck}>
              <img src={checkIcon} alt='check icon' />
            </div>
            <div className={styles.progressStepText}>Payment</div>
          </div>
          <div className={styles.progressStepLine}></div>
        </div>
        <div className={styles.progressStep}>
          <div className={styles.progressStepInner}>
            <div className={styles.progressStepCheck}>
              <img src={checkIcon} alt='check icon' />
            </div>
            <div className={styles.progressStepText}>Shipping</div>
          </div>
          <div className={styles.progressStepLine}></div>
        </div>
        <div className={styles.progressStep}>
          <div className={styles.progressStepInner}>
            <div className={styles.progressStepCheck}>
              <img src={checkIcon} alt='check icon' />
            </div>
            <div className={styles.progressStepText}>Place Order</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgressMobile = ({ activeSection }) => {
  const isActiveStep = (stepId) => {
    const order = ['productSelect', 'payment', 'shipping', 'placeOrder'];
    const currentActiveIndex = order.indexOf(activeSection);
    const stepIndex = order.indexOf(stepId);
    return stepIndex <= currentActiveIndex;
  };

  return (
    <div className={styles.mobileProgress}>
      <div className={styles.mobileProgressInner}>
        {/* Product Select Step */}
        <div
          className={`${styles.mobileProgressStep} ${
            isActiveStep('productSelect') ? styles.mobileProgressStepActive : ''
          }`}
        >
          <div className={styles.mobileProgressStepInner}>
            <div className={styles.mobileProgressStepCheck}>
              <img src={checkIcon} alt='check icon' />
              <img src={checkIcon} alt='check icon' />
            </div>
            <div className={styles.mobileProgressStepText}>
              Select <br></br> quantity
            </div>
          </div>
        </div>
        {/* Payment Step */}
        <div
          className={`${styles.mobileProgressStep} ${
            isActiveStep('payment') ? styles.mobileProgressStepActive : ''
          }`}
        >
          <div className={styles.mobileProgressStepInner}>
            <div className={styles.mobileProgressStepCheck}>
              <img src={checkIcon} alt='check icon' />
              <img src={step2} alt='step 2' />
            </div>
            <div className={styles.mobileProgressStepText}>Payment</div>
          </div>
        </div>
        {/* Shipping Step */}
        <div
          className={`${styles.mobileProgressStep} ${
            isActiveStep('shipping') ? styles.mobileProgressStepActive : ''
          }`}
        >
          <div className={styles.mobileProgressStepInner}>
            <div className={styles.mobileProgressStepCheck}>
              <img src={checkIcon} alt='check icon' />
              <img src={step3} alt='step 3' />
            </div>
            <div className={styles.mobileProgressStepText}>Shipping</div>
          </div>
        </div>
        {/* Place Order Step */}
        <div
          className={`${styles.mobileProgressStep} ${
            isActiveStep('placeOrder') ? styles.mobileProgressStepActive : ''
          }`}
        >
          <div className={styles.mobileProgressStepInner}>
            <div className={styles.mobileProgressStepCheck}>
              <img src={checkIcon} alt='check icon' />
              <img src={step4} alt='step 4' />
            </div>
            <div className={styles.mobileProgressStepText}>Place Order</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function TestCheckout({ offer, onCreateOrder }) {
  const queryParams = buildQueryParams(location);
  const productSelectRef = useRef(null);
  const shippingRef = useRef(null);
  const paymentRef = useRef(null);
  const placeOrderRef = useRef(null);
  const [activeSection, setActiveSection] = useState('');

  const initialProduct = offer.productsData[0];

  const [state, setState] = useState({
    showPayment: false,
    selectedProduct: initialProduct,
    isTermsAgreed: false,
    isTermsError: '',
    shipping: {},
    payment: {},
    ipAddress: '',
    productId: initialProduct.id,
    productQty: initialProduct.quantity,
    productName: initialProduct.name,
    productPrice: initialProduct.price,
    agreeSms: false,
    fbc: getFbcValue(),
    fbp: Cookies.get('_fbp'),
    custom_fields: getCustomFields(),
    orderTotal: initialProduct.price * 1 + initialProduct.shippingPrice * 1,
    campaignId: offer.campaignId,
    shippingId: initialProduct.shippingId || '',
    dynamicProductsData: {},
    offers: [
      {
        billing_model_id: offer.productDetails[initialProduct.id].billingModelId,
        offer_id: offer.productDetails[initialProduct.id].offerId,
        product_id: initialProduct.id,
        price: initialProduct.price,
        productQty: initialProduct.quantity,
        productName: initialProduct.name,
        isMainProduct: true,
      },
    ],
  });

  useEffect(() => {
    const options = {
      root: null, // using the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // trigger when 50% of the element is visible in the viewport
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Based on the target's ID or some other identifying property, update the state
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    // Observe the sections
    if (productSelectRef.current) observer.observe(productSelectRef.current);
    if (shippingRef.current) observer.observe(shippingRef.current);
    if (paymentRef.current) observer.observe(paymentRef.current);
    if (placeOrderRef.current) observer.observe(placeOrderRef.current);

    // Clean up the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  useEffect(() => {
    const { selectedProduct } = state;
    const orderTotal = selectedProduct.price * 1 + selectedProduct.shippingPrice * 1;
    const productDetail = offer.productDetails[selectedProduct.id];

    const stickyOffer = {
      billing_model_id: productDetail.billingModelId,
      offer_id: productDetail.offerId,
      product_id: selectedProduct.id,
      price: selectedProduct.price,
      productQty: selectedProduct.quantity,
      productName: selectedProduct.name,
      isMainProduct: true,
    };

    setState({
      ...state,
      orderTotal,
      offers: [stickyOffer],
      shippingId: selectedProduct.shippingId,
      productId: selectedProduct.id,
      productQty: selectedProduct.quantity,
      productName: selectedProduct.name,
      productPrice: selectedProduct.price,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedProduct]);

  useEffect(() => {
    let componentMounted = true;

    async function getUserIpAddress() {
      try {
        const res = await axios.get('https://api.ipify.org?format=json'); // or any other service like ipify, ipinfo, etc.
        const clientIp = res.data?.ip || '0.0.0.0';

        if (res.data && componentMounted) {
          setState({ ...state, ipAddress: clientIp });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Ip address not found.');
      }
    }

    getUserIpAddress();
    return () => {
      componentMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getFbcValue() {
    if (Cookies.get('_fbc')) {
      return Cookies.get('_fbc');
    }

    if (queryParams.fbclid) {
      const timestamp = new Date().getTime();
      return `fb.1.${timestamp}.${queryParams.fbclid}`;
    }

    return '';
  }

  function getCustomFields() {
    const result = [];
    const fields = {
      17: queryString.parse(location.search).wbraid,
      16: queryString.parse(location.search).gbraid,
      15: queryString.parse(location.search).gclid,
    };

    Object.keys(fields).forEach((key) => {
      if (fields[key]) {
        result.push({
          id: key,
          value: fields[key],
        });
      }
    });

    return result;
  }

  function handleProductSelect(product) {
    setState({ ...state, selectedProduct: product });
  }

  function handleShippingComplete(data) {
    setState({ ...state, shipping: data, showPayment: true });
    placeOrderRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  function handlePaymentComplete(data) {
    if (!state.isTermsAgreed) {
      setState({ ...state, isTermsError: 'You must agree to the terms' });
      return;
    } else {
      setState({ ...state, payment: data, isTermsError: '' });
      const expirationDate = String(data.expiryMonth).padStart(2, '0') + data.expiryYear;
      const orderData = { ...state, ...state.shipping, ...data, expirationDate };
      onCreateOrder(orderData);
    }
  }

  function handleTermsCheckboxClick() {
    setState({ ...state, isTermsAgreed: !state.isTermsAgreed });
  }

  return (
    <div className={styles.checkout}>
      <Headline />
      <Banner />
      <ProgressDesktop />
      <ProgressMobile activeSection={activeSection} />
      <div ref={productSelectRef} id='productSelect'>
        <ProductSelector
          offer={offer}
          selectedProduct={state.selectedProduct}
          onProductSelect={handleProductSelect}
          onOrderNowClick={() => shippingRef.current.scrollIntoView({ behavior: 'smooth' })}
        />
      </div>
      <div ref={paymentRef} id='payment'>
        <PaymentSelector shippingFormRef={shippingRef} />
      </div>
      <div ref={shippingRef} id='shipping'>
        <Shipping
          selectedProduct={state.selectedProduct}
          onComplete={handleShippingComplete}
          brand={offer.brand}
        />
      </div>
      <div ref={placeOrderRef} id='placeOrder'>
        {state.showPayment && (
          <Payment
            isTermsError={state.isTermsError}
            isTermsAgreed={state.isTermsAgreed}
            onComplete={handlePaymentComplete}
            onTermsCheckboxClick={handleTermsCheckboxClick}
          />
        )}
      </div>
    </div>
  );
}
