import queryString from 'query-string';
import base64js from 'base64-js';

export function mapColorsFromProducts(products) {
	const colors = [];

	products.forEach((product) => {
		if (!colors.find((item) => item.name === product.color)) {
			colors.push({
				name: product.color,
				imageSrc: product.colorImage || product.colorimage,
			});
		}
	});

	return colors;
}

export function buildQueryParams(location, saveCase = false) {
	const queryParams = {};
	const qp = saveCase ? queryString.parse(location.search) : queryString.parse(location.search.toLowerCase()); // lower cased to prevent typos

	// If URL has multiple params with same name we going to use last one provided
	Object.keys(qp).forEach((key) => {
		queryParams[key] = typeof qp[key] === 'object' ? qp[key][qp[key].length - 1] : qp[key];
	});

	return queryParams;
}

export function getDollarString(price) {
	// if price has $ sign remove it first
	if (typeof price === 'string' && price.includes('$')) {
		price = price.replace('$', '');
	}

	return (price * 1).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

// Function to convert a string to base64
export function stringToBase64(str) {
	// Convert the string to a byte array
	const byteArray = new TextEncoder().encode(str);

	// Convert the byte array to base64
	const base64 = base64js.fromByteArray(byteArray);

	return base64;
}

export function getAffiliateCbValue(dataLayer) {
	for (let i = 0; i < dataLayer.length; i++) {
		if (dataLayer[i].hasOwnProperty('affiliateCb')) {
			return dataLayer[i].affiliateCb;
		}
	}
	return null;
}

export function getSavingsPercent(oldPrice, price) {
	const decrease = oldPrice - price;
	return (decrease / oldPrice) * 100;
}

export function getPricePerEach(product) {
	return getDollarString(product.price / product.quantity);
}

export function getMaxDiscount(offer) {
	let result = 0;

	offer.productsData.forEach((item) => {
		const percent = getSavingsPercent(item.oldPrice, item.price);

		if (percent > result) {
			result = percent;
		}
	});

	return `${result.toFixed()}%`;
}
