import CrossSell from './CrossSell';
import { buildQueryParams } from '../../utils/helpers';
import './CrossSells.sass';

export default function CrossSells({ onChange, crossSells, offers }) {
	// eslint-disable-next-line no-restricted-globals
	const queryParams = buildQueryParams(location);
	const isND = queryParams.ndcrosssell === '1';

	return (
		<div className={`cross-sells`}>
			{isND ? (
				<div className='cross-sells__title nd-cross-sells__title'>
					<p>
						<b>Don't Forget</b> These Popular Add Ons:
					</p>
					<p>
						Check Box <br></br>to Add
					</p>
				</div>
			) : (
				<div className='cross-sells__title'>
					<i className='fas fa-arrow-alt-down'></i> Popular Add-ons <i className='fas fa-arrow-alt-down'></i>
				</div>
			)}
			{crossSells.map((item, index) => (
				<CrossSell key={index} isND={isND} offers={offers} crossSell={item} onChange={onChange} />
			))}
		</div>
	);
}
